import React from 'react'
import { Helmet } from 'react-helmet'

function Wrapper({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Veen - Lightning Fast RSS Reader</title>
        <meta charset="utf-8" />
        <link rel="canonical" href="http://veenreader.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta content="width=device-width, maximum-scale=1, user-scalable=yes" name="viewport" />

        <meta name="description" content="Veen - Lightning Fast RSS Reader for Web Browsers, Windows, Android and iOS" />
        <meta name="description" content="Veen" />
        <meta name="twitter:site" content="@veenlabs" />
        <meta name="twitter:title" content="Veen" />
        <meta name="twitter:description" content="Veen" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@veenlabs" />
        {/**
          <meta name="twitter:image" content="http://airmailapp.com/img/website-3.0-ios-v5.jpg" />
         */}
        <meta property="og:title" content="Veen" />
        <meta property="og:description" content="Twitter Og Description" />
        <meta property="og:type" content="website" />
        {/**
           * 
           *         <meta property="og:image" content="https://airmailapp.com/img/website-3.0-ios-v5.jpg" />
        <meta property="og:image:secure_url" content="https://airmailapp.com/img/website-3.0-ios-v5.jpg" />
           */}

        <title>Veen - Lightning Fast RSS Reader</title>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1136075622746933" crossorigin="anonymous"></script>
      </Helmet>
      {children}
    </React.Fragment>
  )
}

export default Wrapper
